@import "styles/variables.scss";

.auth-page {
  min-height: calc(var(--vh, 1vh) * 100);
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: $main-background;

  .form-screen {
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;

    @media (max-width: $breakpoint-lg) {
      padding-top: $padding-top-sm;
    }
  }
}

.or-divider {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 5px 0;

  .divider {
    flex: 1;
    height: 1px;
    background: $border;
  }

  .text {
    color: $text-secondary;
    font-size: 20px;

    @media (max-width: $breakpoint-lg) {
      font-size: 16px;
    }
  }
}

.forgot-password-link {
  color: $primary-color;
  font-size: 18px;
  font-weight: 600;
  margin-left: auto;
  user-select: none;
  cursor: pointer;
  transition: 0.2s;
  margin-bottom: 6px;
  margin-top: -14px;

  @media (max-width: $breakpoint-lg) {
    margin-bottom: 0;
    font-size: 14px;
  }
}

.account-text {
  margin: 0 auto;
  margin-top: 20px;
  font-size: 18px;
  margin-top: 64px;

  @media (max-width: $breakpoint-lg) {
    margin-top: auto;
    font-size: 16px;
  }

  .text {
    display: inline;
    color: $text-secondary;
  }

  .link {
    display: inline;
    color: $primary-color;
    user-select: none;
    cursor: pointer;
    font-weight: 600;
  }
}
