@import "styles/variables.scss";

.settings-page {
  min-height: calc(var(--vh, 1vh) * 100);
  height: auto;
  display: flex;
  flex-direction: column;

  &__container {
    max-width: $max-width;
    width: 100%;
    margin: 0 auto;
    padding: $padding;
    position: relative;
    flex-grow: 1;
    padding-top: 56px;

    @media (max-width: $breakpoint-lg) {
      padding-top: 60px;
    }
  }

  .title {
    margin-bottom: 30px;

    @media (max-width: $breakpoint-lg) {
      margin-bottom: 24px;
    }
  }

  .settings-list {
    display: flex;
    flex-direction: column;
    gap: 30px;

    // li {
    //   display: flex;
    //   justify-content: space-between;
    //   padding: 10px 0;
    //   cursor: pointer;
    //   border-bottom: 1px solid #ccc;

    //   &:hover {
    //     background-color: #f9f9f9;
    //   }

    //   .label {
    //     font-weight: bold;
    //   }

    //   .value {
    //     color: #555;
    //   }
    // }
  }

  .settings-list-title {
    font-size: 18px;
    font-weight: 600;
    color: $black;

    @media (max-width: $breakpoint-lg) {
      font-size: 14px;
    }
  }

  .settings-block {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .settings-field {
    width: 100%;
    padding: 16px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border: 1px solid $border;
    border-bottom: none;
    cursor: pointer;
    transition: 0.2s;

    &.disabled {
      pointer-events: none;
      cursor: default;
    }

    &:hover {
      background-color: $primary-light;
    }

    &:last-child {
      border-bottom: 1px solid $border;

      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;

      @media (max-width: $breakpoint-lg) {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

    &:first-child {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;

      @media (max-width: $breakpoint-lg) {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }

    &-left {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    .label {
      font-size: 18px;
      font-weight: 400;
      color: $text-secondary;
      @media (max-width: $breakpoint-lg) {
        font-size: 14px;
      }
    }

    .value {
      font-size: 20px;
      font-weight: 500;

      @media (max-width: $breakpoint-lg) {
        font-size: 16px;
      }
    }
  }
}

.back-label {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: $primary-color;
  transition: 0.2s;

  &.offset {
    margin-bottom: 20px;
  }

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: $breakpoint-lg) {
    display: none;
  }
}
