@import "styles/variables.scss";

.call-prepare {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (max-width: $breakpoint-lg) {
    gap: 20px;
  }

  .summary-box {
    padding: 32px;
    border: 1px solid $border;
    border-radius: 16px;
    width: 100%;

    @media (max-width: $breakpoint-lg) {
      border-radius: 10px;
      padding: 20px 22px 23px 16px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      max-width: 410px;
      width: 100%;
      gap: 32px;

      @media (max-width: $breakpoint-lg) {
        gap: 24px;
      }
    }

    &__title {
      color: $black;
      font-size: 20px;
      font-weight: 600;

      @media (max-width: $breakpoint-lg) {
        font-size: 16px;
      }
    }

    &__location {
      display: flex;
      align-items: center;
      gap: 24px;
      .arrow-circle {
        min-height: 40px;
        min-width: 40px;
        max-height: 40px;
        max-width: 40px;

        @media (max-width: $breakpoint-lg) {
          min-height: 32px;
          min-width: 32px;
          max-height: 32px;
          max-width: 32px;
        }
      }
    }

    &__location-item {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &__location-label {
      color: $black;
      font-size: 22px;
      font-weight: 700;

      @media (max-width: $breakpoint-lg) {
        font-size: 18px;
      }
    }

    &__location-value {
      color: $black;
      font-size: 16px;
      font-weight: 400;

      @media (max-width: $breakpoint-lg) {
        font-size: 12px;
      }
    }

    &__tags {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;

      @media (max-width: $breakpoint-lg) {
        gap: 6px;
      }
    }

    &__tag {
      font-size: 18px;
      font-weight: 400;
      padding: 8px 20px;
      color: $text-secondary;
      background-color: $primary-light;
      border-radius: 38px;

      @media (max-width: $breakpoint-lg) {
        padding: 4px 10px;
        font-size: 14px;
      }
    }

    &__pickup {
      &-label {
        color: $text-secondary;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 4px;

        @media (max-width: $breakpoint-lg) {
          font-size: 14px;
        }
      }

      &-value {
        color: $black;
        font-size: 18px;
        font-weight: 400;

        @media (max-width: $breakpoint-lg) {
          font-size: 14px;
        }
      }
    }
  }

  .found-loads-box,
  .warning-box {
    padding: 28px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
    border-radius: 16px;

    .text {
      text-align: center;
      font-size: 18px;

      @media (max-width: $breakpoint-lg) {
        font-size: 14px;
      }
    }

    svg {
      height: 32px;
      width: auto;
    }

    @media (max-width: $breakpoint-lg) {
      padding: 24px 19px;
      gap: 8px;

      svg {
        height: 24px;
      }
    }
  }

  .found-loads-box {
    background-color: #e9e7ff;
    border: 1px solid $primary-color;
    min-height: 97px;

    .bold {
      font-weight: 600;
      color: $primary-color;
    }

    &.error {
      background-color: #fff5f5;
      border: 1px solid $alert-negative;

      .bold {
        color: $alert-negative;
      }
    }
  }

  .warning-box {
    background-color: #fffcf5;
    border: 1px solid #f79726;
  }
}
