@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import "styles/variables.scss";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: "Inter", sans-serif;
}

* {
  font-family: "Inter", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.2s;

  &:hover {
    text-decoration: underline;
  }
}

.title {
  font-size: 32px;
  font-weight: 700;
  color: $black;

  @media (max-width: $breakpoint-lg) {
    font-size: 24px;
  }

  &.contrast {
    color: $white;
  }

  &.center {
    text-align: center;
  }
}

.subtitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: $text-secondary;

  @media (max-width: $breakpoint-lg) {
    font-size: 16px;
    line-height: 24px;
  }

  &.contrast {
    color: rgba(255, 255, 255, 0.8);
  }

  &.center {
    text-align: center;
  }
}

form {
  height: 100%;
}

.back-icon {
  position: absolute;
  top: 24px;
  left: 16px;
  cursor: pointer;
  height: 24px;
  width: 24px;
  display: none;

  @media (max-width: $breakpoint-lg) {
    display: block;
  }
}

/* Remove arrows from number inputs */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.desktop-only {
  @media (max-width: $breakpoint-lg) {
    display: none;
  }
}

.mobile-only {
  display: none;

  @media (max-width: $breakpoint-lg) {
    display: block;
  }
}

.highlight {
  color: $primary-color;
}

.input-footer-link {
  font-size: 14px;
  font-weight: 500;
  color: $primary-color;
  cursor: pointer;
  margin-top: 8px;
  transition: 0.2s;
  opacity: 0.8;
  display: flex;
  justify-content: end;
  margin-top: 8px;

  &:hover {
    opacity: 0.6;
  }
}
