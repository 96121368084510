@import "styles/variables.scss";

.home {
  min-height: calc(var(--vh, 1vh) * 100);
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: $light-background;

  &__container {
    display: flex;
    flex-direction: column;
    max-width: 894px;
    width: 100%;
    margin: 0 auto;
    padding: $padding;
    gap: 32px;

    justify-content: center;
    padding-top: 80px;
    padding-bottom: 40px;

    @media (max-width: $breakpoint-lg) {
      padding-top: 30px;
      gap: 20px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 2px;
    justify-content: space-between;
  }

  &__header-left {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__header-right {
    display: flex;
    align-items: center;
    gap: 11px;

    @media (max-width: $breakpoint-lg) {
      gap: 5px;
    }
  }

  &__header-title {
    font-size: 24px;
    font-weight: 700;
    color: $black;

    @media (max-width: $breakpoint-lg) {
      font-size: 16px;
    }
  }

  &__header-logo {
    height: 40px;
    width: auto;

    @media (max-width: $breakpoint-lg) {
      height: 32px;
    }
  }

  &__header-button {
    display: flex;
    align-items: center;
    gap: 6px;
    color: $primary-color;
    cursor: pointer;
    user-select: none;
    border-radius: 8px;
    padding: 11px;
    border: 2px solid $primary-color;
    width: fit-content;
    background-color: $light-background;
    transition: 0.2s;

    span {
      font-weight: 500;
      font-size: 14px;
    }

    svg {
      fill: $primary-color;
      height: 17px;
      width: auto;
    }

    &:hover {
      background-color: $white-hover;
    }

    @media (max-width: $breakpoint-lg) {
      padding: 9px;
      border: 1px solid $primary-color;

      span {
        display: none;
      }

      svg {
        height: 14px;
      }
    }
  }

  &__main-card {
    border-radius: 16px;
    padding: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: $primary-color;
    background-image: url(../../images/card_bg.png);
    background-position: center;
    background-size: cover;

    @media (max-width: $breakpoint-lg) {
      gap: 12px;
      padding: 32px 16px;
      background-image: url(../../images/card_bg_mobile.png);
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-title {
      color: $white;
      font-size: 28px;
      font-weight: 700;

      @media (max-width: $breakpoint-lg) {
        font-size: 20px;
      }
    }

    &-button {
      border: none;
      cursor: pointer;
      padding: 12px 20px;
      font-size: 18px;
      font-weight: 600;
      color: $primary-color;
      border-radius: 8px;
      background-color: $white;
      transition: 0.2s;

      &:hover {
        background-color: $white-hover;
      }

      @media (max-width: $breakpoint-lg) {
        padding: 8.5px 16px;
        font-size: 12px;
      }
    }
  }

  &__main-card-info {
    display: flex;
    align-items: center;
    gap: 20px;

    .home__card-info-item {
      flex: unset;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
    }

    .home__card-info-title {
      color: $white;
    }

    .home__card-info-description {
      color: $white;
    }
  }

  &__card {
    padding: 40px;
    display: flex;
    justify-content: space-between;
    gap: 80px;
    border-radius: 16px;
    background-color: $white;
    align-items: center;

    @media (max-width: $breakpoint-lg) {
      padding: 32px 24px;
      gap: 20px;
      flex-direction: column;
      justify-content: unset;
      align-items: unset;
    }

    &-text {
      display: flex;
      flex-direction: column;
      gap: 16px;

      @media (max-width: $breakpoint-lg) {
        gap: 20px;
      }
    }

    &-header {
      display: flex;
      align-items: center;
      gap: 20px;

      @media (max-width: $breakpoint-lg) {
        gap: 16px;
      }

      svg {
        width: 40px;
        height: auto;
      }

      span {
        font-size: 24px;
        font-weight: 600;

        @media (max-width: $breakpoint-lg) {
          font-size: 20px;
        }
      }
    }

    &-description {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: $text-secondary;
    }

    &-buttons {
      display: flex;
      flex-direction: column;
      gap: 16px;
      max-width: 300px;
      width: 100%;

      @media (max-width: $breakpoint-lg) {
        gap: 12px;
        min-width: unset;
        max-width: unset;
      }
    }
  }
}

.logout-button {
  display: flex;
  align-items: center;
  gap: 8px;
  color: $primary-color;
  cursor: pointer;
  user-select: none;
  border-radius: 12px;
  padding: 16px 32px;
  border: 2px solid $primary-color;
  width: fit-content;
  background-color: #fff;
  transition: 0.2s;

  span {
    font-weight: 600;
    font-size: 18px;
  }

  svg {
    fill: $primary-color;
    height: 24px;
    width: auto;
  }

  &:hover {
    background-color: $white-hover;
  }

  @media (max-width: $breakpoint-lg) {
    border-radius: 10px;
    padding: 10px 20px;

    span {
      font-size: 14px;
    }

    svg {
      height: 20px;
    }
  }
}
