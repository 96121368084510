@import "styles/variables.scss";

.how {
  background-color: $main-background;
  padding: 40px 20px;
  height: 100%;

  @media (max-width: $breakpoint-lg) {
    padding: 16px;
  }

  &__container {
    max-width: 1145px;
    width: 100%;
    margin: 0 auto;
  }

  &__back-button {
    margin-bottom: 16px;

    .back-icon {
      position: initial;
    }
  }

  &__heading {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 24px;

    @media (max-width: $breakpoint-lg) {
      font-size: 24px;
    }
  }

  &__content {
    border-radius: 16px;
    background-color: $white;
    padding: 120px 16px;

    @media (max-width: $breakpoint-lg) {
      padding: 80px 16px;
      border-radius: 10px;
    }

    &-container {
      max-width: 936px;
      width: 100%;
      margin: 0 auto;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    max-width: 590px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 120px;

    @media (max-width: $breakpoint-lg) {
      margin-bottom: 80px;
    }

    &-title {
      font-size: 32px;
      font-weight: 700;
      text-align: center;

      @media (max-width: $breakpoint-lg) {
        font-size: 20px;
      }
    }

    &-description {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: $text-secondary;

      @media (max-width: $breakpoint-lg) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .how-it-works-title {
    margin-bottom: 100px;
    @media (max-width: $breakpoint-lg) {
      margin-bottom: 40px;
    }
  }

  &__footer {
    margin-top: 120px;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    &-text {
      font-size: 24px;
      font-weight: 700;

      @media (max-width: $breakpoint-lg) {
        font-size: 20px;
      }
    }

    .button {
      width: fit-content;
    }
  }
}

.stepper {
  position: relative;
  display: flex;
  flex-direction: column;

  @media (max-width: $breakpoint-lg) {
    align-items: center;
  }

  &__step {
    position: relative;
    margin-bottom: 80px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: $breakpoint-lg) {
      margin-bottom: 80px;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      width: 2px;
      background-color: $primary-color;
      transform: translateX(-50%);

      @media (max-width: $breakpoint-lg) {
        display: none;
      }
    }

    &:before {
      top: -40px;
      bottom: 50%;
    }

    &:after {
      top: 50%;
      bottom: -40px;
    }

    &:first-child:before {
      display: none;
    }

    &:last-child:after {
      display: none;
    }

    .stepper__number {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;

      @media (max-width: $breakpoint-lg) {
        display: none;
      }

      .number-circle {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $main-background;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 18px;
        color: $primary-color;
      }
    }

    .stepper__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 80px;

      &.reverse {
        flex-direction: row-reverse;

        @media (max-width: $breakpoint-lg) {
          flex-direction: column;
        }
      }

      @media (max-width: $breakpoint-lg) {
        flex-direction: column;
        gap: 16px;
      }

      .stepper__image,
      .stepper__text {
        width: calc(50% - 80px);

        @media (max-width: $breakpoint-lg) {
          width: 100%;
        }
      }

      .stepper__text {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media (max-width: $breakpoint-lg) {
          gap: 8px;
        }

        &-title {
          font-size: 24px;
          font-weight: 700;

          @media (max-width: $breakpoint-lg) {
            font-size: 16px;
          }
        }

        &-description {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: $text-secondary;

          @media (max-width: $breakpoint-lg) {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      .stepper__image {
        img {
          max-width: 100%;
          height: auto;
        }

        @media (max-width: $breakpoint-lg) {
          order: 2;
        }
      }

      .stepper__text {
        @media (max-width: $breakpoint-lg) {
          order: 1;
        }
      }
    }
  }
}
