@import "styles/variables.scss";

.install {
  min-height: calc(var(--vh, 1vh) * 100);
  height: auto;
  background-color: $light-background;
  display: flex;
  align-items: center;

  &__container {
    display: flex;
    align-items: center;
    padding: 80px;
    gap: 120px;
    max-width: 1600px;
    margin: 0 auto;

    @media (max-width: $breakpoint-lg) {
      flex-direction: column;
      gap: 20px;
      padding: $padding;
    }
  }

  &__content {
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;

    .button {
      width: fit-content;
      font-size: 18px;
      padding: 10px 32px;
      border-radius: 8px;
    }

    @media (max-width: $breakpoint-lg) {
      gap: 16px;
    }
  }

  &__image {
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__title {
    font-size: 40px;
    font-weight: 700;

    @media (max-width: $breakpoint-lg) {
      font-size: 24px;
    }
  }

  &__description {
    font-size: 16px;
    font-weight: 400;
    color: #637381;

    @media (max-width: $breakpoint-lg) {
      font-size: 14px;
    }
  }
}
