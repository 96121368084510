@import "styles/variables.scss";

.button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 14px 16px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  transition: 0.2s;
  background: $primary-color;
  color: $white;
  cursor: pointer;
  border: 2px solid transparent;
  user-select: none;

  span {
    font-size: inherit;
  }

  @media (max-width: $breakpoint-lg) {
    font-size: 16px;
    border-radius: 8px;
  }

  &:hover {
    background: #062064;
  }

  &.disabled {
    background-color: $disabled !important;
    color: $white !important;
    cursor: not-allowed;
    pointer-events: none;
  }

  &.secondary {
    border: 2px solid $primary-color;
    color: $primary-color;
    background: transparent;

    &:hover {
      background-color: $white-hover;
    }
  }

  &.secondary-reverse {
    border: 2px solid $white;
    color: $white;
    background: $primary-color;

    &:hover {
      background: #062064;
    }
  }

  &.contrast {
    background: $white;
    border: 2px solid transparent;
    color: $black;

    &:hover {
      background: rgb(209, 209, 209);
    }
  }

  &.bottom {
    position: absolute;
    bottom: 110px;

    @media (max-width: $breakpoint-lg) {
      bottom: 40px;
    }
  }

  &.desktop-only {
    @media (max-width: $breakpoint-lg) {
      display: none;
    }
  }

  &.google-button {
    background: $white;
    color: $black;
    border: 1px solid $border;

    &:hover {
      background: rgb(231, 231, 231);
    }
  }
}
