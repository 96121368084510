@import "styles/variables.scss";

.support-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  &__container {
    position: relative;
    max-width: 538px;
    width: 100%;
    margin: 16px;
    background: $white;
    padding: 38px;
    border-radius: 20px;
    z-index: 1001;

    @media (max-width: $breakpoint-lg) {
      border-radius: 10px;
    }

    h2 {
      margin-bottom: 24px;
    }
  }

  &__content {
    max-width: 381px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 4px;

    @media (max-width: $breakpoint-lg) {
      font-size: 20px;
      margin-bottom: 8px;
    }
  }

  &__subtitle {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 4px;
    margin-bottom: 32px;
    text-align: center;

    @media (max-width: $breakpoint-lg) {
      font-size: 14px;
      margin-bottom: 39px;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__item {
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #dfe3eb;
    width: 100%;

    svg {
      fill: $black;
    }

    &.mobile {
      border: none;
    }
  }

  &__copy-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: 0.2s;

    &:hover {
      transform: scale(1.05);
      opacity: 0.9;
    }
  }

  &__icon-wrapper {
    padding: 7px;
    border-right: 1px solid #dfe3eb;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__contact {
    padding: 3px 7.5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 7px;

    span {
      font-size: 14px;

      @media (max-width: $breakpoint-lg) {
        font-size: 12px;
      }
    }
  }

  &__call-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    background: $primary-color;
    color: $white;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    height: 26px;

    svg {
      fill: $white;
    }

    span {
      font-size: 12px;
    }
  }

  &__close-button {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    transition: 0.2s;

    &:hover {
      transform: scale(1.07);
    }
  }
}
