$primary-color: #082980;
$primary-light: #ecebfd;
$disabled: #dadfec;
$black: #000000;
$main-background: #f3f4f9;
$light-background: #f3f4fa;
$white-hover: #e7e7e7;
$text-secondary: #777e8a;
$border: #dfe3eb;
$white: #ffffff;
$error: #e73610;
$alert-negative: #f00;
$max-width: 503px;
$padding: 16px;
$padding-top-xl: 120px;
$padding-top-sm: 70px;

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;

$vh: 100vh;

:root {
  --vh: #{$vh};
}
