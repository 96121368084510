@import "styles/variables.scss";

.edit {
  min-height: calc(var(--vh, 1vh) * 100);
  height: auto;
  display: flex;
  flex-direction: column;

  &__container {
    min-height: calc(var(--vh, 1vh) * 100);
    height: auto;
    max-width: $max-width;
    width: 100%;
    margin: 0 auto;
    padding: $padding;
    position: relative;
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;

    @media (max-width: $breakpoint-lg) {
      justify-content: space-between;
      padding-top: 60px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      margin-bottom: 10px;

      @media (max-width: $breakpoint-lg) {
        margin-bottom: 4px;
      }
    }
  }
}
