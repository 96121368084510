@import "styles/variables.scss";

.informative-screen {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  .big-logo {
    margin-bottom: 20px;
    width: 348px;
    height: auto;

    @media (max-width: $breakpoint-lg) {
      max-width: 200px;
      width: 100%;
      margin-bottom: 22px;
    }
  }
}

/* Form-specific styling */
.form-group {
  label {
    font-size: 18px;
    font-weight: 500;
    display: block;
    margin-bottom: 8px;

    @media (max-width: $breakpoint-lg) {
      font-size: 14px;
    }
  }

  &.small {
    max-width: 152px;
  }

  .input-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    .input-icon {
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
      max-height: 24px;
      height: 100%;
      width: auto;
    }

    .input-icon-right {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }

    .input,
    textarea,
    select {
      padding-left: 48px;
    }
  }

  .input,
  textarea,
  select {
    width: 100%;
    padding: 16px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    border-radius: 5px;
    transition: 0.2s;
    background-color: $white;
    border-radius: 12px;
    border: none;
    font-family: "Inter", sans-serif;
    resize: none;

    &::placeholder {
      color: $text-secondary;
      font-weight: 400;
    }

    &:disabled {
      background-color: #f5f5f5;
      color: $black;
      cursor: not-allowed;
    }

    @media (max-width: $breakpoint-lg) {
      font-size: 16px;
      line-height: 24px;
      border-radius: 10px;
    }

    &:focus {
      background-color: $primary-light;
      outline: none;
    }
  }

  .error-message {
    color: $error;
    font-size: 12px;
    margin-top: 4px;
  }

  .select__placeholder {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    margin-left: 5px;

    @media (max-width: $breakpoint-lg) {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

/* Styling for trailer type pills in the Equipment Details step */
.trailer-types {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;

  label {
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]:checked + label {
    background-color: #007bff;
    color: white;
  }
}

.form-container {
  max-width: $max-width;
  width: 100%;
  margin: 0 auto;
  padding: $padding;
  padding-bottom: 70px;
  position: relative;
  flex-grow: 1;
}

.form-wrapper {
  height: 100%;
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  height: auto;
  display: flex;
  flex-direction: column;

  &.primary-bg {
    background-color: $primary-color;
  }
}

.form-screen {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: $padding-top-xl;

  @media (max-width: $breakpoint-lg) {
    padding-top: $padding-top-sm;

    &.no-padding {
      padding-top: 40px;
    }
  }
}

.buttons-container {
  position: absolute;
  bottom: 110px;
  left: $padding;
  right: $padding;
  display: flex;
  align-items: center;
  gap: 20px;

  &.static {
    position: unset;
  }

  &.top-margin {
    margin-top: 40px;

    @media (max-width: $breakpoint-lg) {
      margin-top: 20px;
    }
  }

  @media (max-width: $breakpoint-lg) {
    &.static-mobile {
      position: unset;
    }

    flex-direction: column;
    bottom: 40px;
    gap: 20px;
  }
}

.title-subtitle {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  @media (max-width: $breakpoint-lg) {
    gap: 10px;
  }
}

.fields-container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .title {
    margin-bottom: 6px;

    @media (max-width: $breakpoint-lg) {
      margin-bottom: 8px;
    }
  }
}

.select__control {
  min-height: 58px !important;
  border-color: $border !important;
  border-radius: 12px !important;

  @media (max-width: $breakpoint-lg) {
    border-radius: 10px !important;
  }
}

.select__indicator-separator {
  background-color: $border !important;
}

.row-fields {
  display: flex;
  align-items: start;
  gap: 24px;

  .form-group {
    flex: 1;
  }
}

.react-datepicker {
  border-radius: 10px !important;
  border: 1px solid $border !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.select__option--is-focused {
  background-color: $primary-light !important;
}

.select__option--is-selected {
  background-color: $primary-color !important;
}

.react-datepicker__day--in-range {
  background-color: $primary-color !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #0828807b !important;
}

.react-datepicker__header {
  background-color: $white !important;
  border-radius: 10px;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 10px !important;
}

.react-datepicker__header {
  border-bottom: 1px solid $border !important;
}

.react-datepicker-popper .react-datepicker__triangle {
  stroke: $border !important;
}

.pac-container {
  border-radius: 10px !important;
}

.pac-item {
  border-top: 1px solid $border !important;
}

.screen-icon {
  height: 120px;
  width: 120px;

  @media (max-width: $breakpoint-lg) {
    height: 80px;
    width: 80px;
  }
}

.multiform {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-subtitle {
  &.subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 12px;

    @media (max-width: $breakpoint-lg) {
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.step-label {
  color: $black;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 12px;

  @media (max-width: $breakpoint-lg) {
    margin-bottom: 16px;
  }
}

.checkmark-minimal-icon {
  height: 56px;
  width: 56px;

  @media (max-width: $breakpoint-lg) {
    height: 43px;
    width: 43px;
  }
}

.outline-form-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 10px;
  background-color: $white;
  padding: 32px;

  @media (max-width: $breakpoint-lg) {
    padding: 20px;
  }

  .input,
  textarea,
  select {
    border: 1px solid $border;
  }
}

.info-field {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__label {
    font-size: 16px;
    font-weight: 400;
    color: $text-secondary;

    @media (max-width: $breakpoint-lg) {
      font-size: 14px;
    }
  }

  &__value {
    font-size: 18px;
    font-weight: 500;

    @media (max-width: $breakpoint-lg) {
      font-size: 16px;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    gap: 16px;

    .info-field {
      width: 100%;
    }
  }
}
