@import "styles/variables.scss";

.listen {
  width: 100%;
  padding: 0 $padding;
  z-index: 20;
  position: relative;
  background: url("../../images/audio-backgroun.png") no-repeat center center;
  background-size: cover;
  background-color: white;
  box-shadow: 0px 4px 30px 0px rgba(3, 17, 51, 0.05);
  border-radius: 10px;

  &__arrow {
    position: absolute;
    bottom: 0;
    left: 50px;

    @media (max-width: $breakpoint-lg) {
      left: 30px;
    }
  }

  &__wrapper {
    position: relative;
    z-index: 30;
    margin: 0 auto;
    width: 100%;
    max-width: 1084px;
    border-radius: 22px;
    padding: 48px 44px;

    @media (max-width: $breakpoint-lg) {
      padding: 8px;
      padding: 44px 20px;
    }
  }

  &__title {
    color: $black;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    max-width: 352px;

    @media (max-width: $breakpoint-lg) {
      max-width: 220px;
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
    }
  }

  &__pills {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (max-width: $breakpoint-lg) {
      gap: 12px;
    }
  }

  &__text-pill {
    color: $black;
    font-size: 12px;
    font-weight: 500;
    padding: 12px 20px;
    border-radius: 12px;
    background: #f6f7f8;
    margin-right: auto;
    width: fit-content;
    display: flex;
    align-items: center;

    @media (max-width: $breakpoint-lg) {
      font-size: 11px;
      padding: 11px 18px;
      border-radius: 8px;
    }

    .icon {
      font-size: 14px;
      margin-right: 8px;
      display: inline;

      @media (max-width: $breakpoint-lg) {
        font-size: 12px;
      }
    }
  }

  &__audio {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 12px;
    background: linear-gradient(144deg, #5679d6 3.95%, #082980 77.16%);
    margin-left: auto;
    width: fit-content;

    @media (max-width: $breakpoint-lg) {
      padding: 7.5px 11px;
      gap: 8px;
      border-radius: 8px;
    }

    .play,
    .pause {
      height: auto;
      min-width: 15px;
      max-width: 15px;
      cursor: pointer;
      transition: 0.2s;
      scale: 1;

      &:hover {
        scale: 1.1;
      }

      @media (max-width: $breakpoint-lg) {
        max-width: 14px;
        min-width: 14px;
      }
    }

    .audio-track {
      height: 22px;
      width: auto;

      @media (max-width: $breakpoint-lg) {
        height: 21px;
      }
    }

    .audio-track-wrapper {
      display: flex;
      align-items: center;
    }
  }
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}
